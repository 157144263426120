<template>
  <!-- 大屏展示 -->
  <div class="bigshow">
    <div class="bigTop">
      <div class="bignum">
        <img src="../../assets/shebeizongshu.png" alt="" />
        <div class="bigdis">
          <p>设备投放量</p>
          <p>
            <span>{{ tou }}</span>
          </p>
        </div>
      </div>
      <div class="bignum">
        <img src="../../assets/peiwang.png" alt="" />
        <div class="bigdis">
          <p>运行总数</p>
          <p>
            <span>{{ yunxing }}</span> 次
          </p>
        </div>
      </div>
      <div class="bignum">
        <img src="../../assets/shenguangbaojing.png" alt="" />
        <div class="bigdis">
          <p>
            报警设备数量 <span class="bigxiang" @click="detailRou">详情></span>
          </p>
          <p>
            <span>{{ bao }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="showMain">
      <div class="bigconvas">
        <el-amap vid="amapDemo" :center="center" :zoom="zoom" class="amap-demo">
          <!-- 一定要添加:content="marker.content"********* -->
          <el-amap-marker
            v-for="(marker, index) in markers"
            :position="marker.position"
            :key="index"
          >
            <span
              class="el-icon-location"
              v-show="marker.str == 0"
              style="color: #567722; font-size: 40px"
            ></span>
            <span
              class="el-icon-location"
              style="color: red; font-size: 40px"
              v-show="marker.str == 1"
            ></span>
          </el-amap-marker>
        </el-amap>
      </div>
    </div>
  </div>
</template>

<script>
import flag from "../../assets/flag.png";
import { device } from "../../api/equip/device";
import { errorlist } from "../../api/alarm/list";
export default {
  components: {},
  data() {
    // const center = [121.59996, 31.197646];
    return {
      tou: "",
      yunxing: null,
      bao: "",
      zoom: 5,
      center: [0, 0],
      markers: [],
      flag: flag,
    };
  },
  watch: {},
  created() {
    // 监听窗口全屏
    window.addEventListener("resize", this.b);
    device("", "", "10000000000").then((res) => {
      console.log(res);
      this.yunxing = res.data.functionnumber
      this.tou = res.data.total + "台";
      let markers = [];
      let basePosition = res.data.data;
      for (let i = 0; i < basePosition.length; i++) {
        // console.log(basePosition);
        markers.push({
          position: [basePosition[i].lat, basePosition[i].lng],
          content: `content ${i}`,
          str: basePosition[i].status,
        });
      }

      this.markers = markers;
      console.log(this.markers);
      this.center = [basePosition[0].lat, basePosition[0].lng];
    });
    errorlist("", "", "", "", 5).then((res) => {
      console.log(res);
      this.bao = res.warn_device + "台";
    });
    // content添加相应的内容**********
  },
  mounted() {},
  methods: {
     b(){
      if(this.$route.path == '/bigshow1'){
        this.a()
      }else{
        console.log('用户端')
      }
    },
    // 监听全屏
    a(){ 
      if (window.outerHeight === screen.availHeight){
        if(window.outerWidth === screen.availWidth && this.$route.path == '/bigshow1'){
          console.log("不是全屏1");
          this.$router.push('/bigshow')
        }else{
          console.log("不是全屏2");
        }
      }else{
        console.log("是全屏2");
        this.$router.push('/bigshow1')
      }
    },
    detailRou() {
      this.$router.push("/alarm");
    },
  },
  beforeDestroy(){
    window.removeEventListener("resize", this.b)
  }
};
</script>

<style  lang='less' scoped>
* {
  box-sizing: border-box;
}                                                                        
.bigshow {
  //padding: 20px;
  .bigTop {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .bigdis {
      p:nth-of-type(2) {
        font-size: 36px;
        margin-top: 5px;
      }
    }
    .bignum {
      height: 130px;
      width: 33.33333333333333333%;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      border-right: 2px solid #f2f2f2;
      img {
        width: 74px;
        height: 74px;
        margin-right: 15px;
      }
      p {
        font-size: 28px;
        margin: 0px;
        margin-top: 5px;
        span {
          font-size: 36px;
          font-weight: 600;
        }
        .bigxiang {
          font-size: 22px;
          color: #1f6df9;
          margin-left: 15px;
          cursor: pointer;
        }
      }
    }
  }
  .bigconvas {
    width: 100%;
    height: 910px;
    background: white;
  }
}
</style>